<template>
    <div>
        <div class="filter-panel">
            <CSSelect i-width="36rpx" style="width: 230px; margin-right: 0">
                <el-date-picker
                        type="datetime"
                        placeholder="请选择开始时间"
                        v-model="queryParams.startTime"
                        style="width: 214px"
                        format="yyyy-MM-dd HH:mm"
                        value-format="yyyy-MM-dd HH:mm"
                        prefix-icon="el-icon-time"
                        :editable="false"
                >
                </el-date-picker>
            </CSSelect>
            <span class="inline-gray"> </span>
            <CSSelect i-width="36rpx" style="width: 230px">
                <el-date-picker
                        type="datetime"
                        placeholder="请选择结束时间"
                        v-model="queryParams.endTime"
                        style="width: 214px"
                        format="yyyy-MM-dd HH:mm"
                        value-format="yyyy-MM-dd HH:mm"
                        prefix-icon="el-icon-time"
                        :editable="false"
                >
                </el-date-picker>
            </CSSelect>
            <input
                    type="text"
                    class="cs-input"
                    placeholder="搜索车牌"
                    v-model="queryParams.search"
            />
            <CSSelect>
                <select v-model="queryParams.type">
                    <option value="">车辆类型不限</option>
                    <option value="1">临时车</option>
                    <option value="2">月租车</option>
                    <option value="3">免费车</option>
                </select>
            </CSSelect>
            <button class="btn btn-primary" @click="queryInRecord()">查询</button>
        </div>
        <div class="result-panel">

            <div style="padding-top: 20px;margin-left: 30px">
                场内车辆：{{total}}
            </div>
            <hr>
            <CSTable :thead-top="filterHeight">
                <template v-slot:thead>
                    <tr>

                        <th>车牌</th>
                        <th>入场时间</th>
                        <th>车辆类型</th>
                        <th>停车时长</th>
                        <th>车辆图片</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <template v-if="inRecord">
                        <tr v-for="record in inRecord" :key="record.id">
                            <td>{{ record.carNumber || "-" }}</td>
                            <td style="min-width: 144px">{{ record.inTime || "-" }}</td>
                            <td v-if="record.type === 1">临时车</td>
                            <td v-else-if="record.type === 2">月租车</td>
                            <td v-else-if="record.type === 3">免费车</td>
                            <td>{{ record.stopTime || "-" }}</td>
                            <td style="min-width: 144px">
                                <span
                                        v-if="record.inPicture"
                                        class="allow-click"
                                        @click="checkImg(record.inPicture)"
                                >
                查看
              </span>
                                <template v-else> -</template>
                            </td>
                        </tr>
                    </template>
                </template>
            </CSTable>

            <pagination/>
        </div>
<!--        <ViewImageModal/>-->
        <CSDialog
                :dialogTitle="`查看车辆`"
                :dialog-visible="checkoutImg"
                dialog-width="700px"
                @onClose="checkoutImg=false"
                dialogCancelBtnText="关闭"
                class="oppositeName"
                :dialogShowConfirmBtn="false"
                dialogHeaderClass="alert-bg"
        >
            <div slot="dialog-content" class="room-form">
                <CSImage :srcImg="imgUrlsd">

                </CSImage>
            </div>

        </CSDialog>

    </div>
</template>

<script>
    import CSSelect from "@/components/common/CSSelect";
    import CSTable from "@/components/common/CSTable";
    import Pagination from "@/components/Pagination";
    import ViewImageModal from "@/components/ViewImageModal";
    import dayjs from "dayjs";
    import {queryVenueCarUrl} from "@/requestUrl";
    import CSDialog from "@/components/common/CSDialog";
    import CSImage from "@/components/common/CSImage";
    export default {
        name: "VenueCar",
        components: {
            CSSelect,
            CSTable,
            Pagination,
            ViewImageModal,
            CSImage,
            CSDialog
        },
        data() {
            return {
                checkoutImg:false,
                imgUrlsd:'',
                filterHeight: -20,
                queryParams: {
                    startTime: dayjs().format("YYYY-MM-DD 00:00"),
                    // startTime: dayjs().subtract(90, "day").format("YYYY-MM-DD 00:00"),
                    endTime: dayjs().format("YYYY-MM-DD 23:59"),
                    search: "",
                    type: ""
                },
                inRecord: [],
                total: ""
            }
        },
        created() {
            this.$vc.on(this.$route.path, "pagination_page", "event", this.queryInRecord);
            this.queryInRecord();
        },
        mounted() {
            this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
            window.addEventListener("resize", () => {
                this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
            });
        },
        methods: {
            queryInRecord(pageNo = 1, pageSize = 10) {
                this.$fly
                    .post(queryVenueCarUrl, {
                        regionCode: this.$vc.getCurrentRegion().code,
                        pageNo,
                        pageSize,
                        ...this.queryParams,
                    })
                    .then((res) => {
                        if (res.code === 0) {
                            this.inRecord = res.data.datas;
                            this.total = res.data.total
                            this.$vc.emit(this.$route.path, "pagination", "init", {
                                total: res.data.total,
                                pageSize,
                                currentPage: pageNo,
                            });
                        }
                        console.log(this.inRecord)
                    });
            },
            checkImg(imgUrl) {
                this.imgUrlsd = imgUrl;
                this.checkoutImg = true;
                // this.$vc.emit(this.$route.path, "viewImageModal", "view", imgUrl);
            }
        }
    }
</script>

<style scoped>

</style>
